import {useState, useEffect} from "react"
import "./rptotu.scss"
import apiCall from "../../../utils/apiFunction/apiCall"
import uploadPresigned from "../../../utils/presignedUpload"
import DynamicForm from "../../../component/common/DynamicForm/dynamicForm"
import {toast} from "react-toastify"
import moment from "moment"
import SystemXLoader from "../../../component/common/Loader/SystemXLoader"
import {useSelector} from "react-redux"
import {useNavigate } from "react-router-dom";
import {
  apiConstants,
  errMessage,
  toastIds,
  successMsg,
} from "../../../constants/Common/apiConstants"
import {
  S3FILEPATH,
  dateFormats,
  route
} from "../../../constants/Common/commonConstants"
import { ROUTE_PATH } from "../../../constants/Routes"


const RptOtu = () => {
  const [uploadPercent, setUploadPercent] = useState(0)
  const [loading, setLoading] = useState(false)
  const [fileData, setFileData] = useState([])
  const loginState = useSelector((state) => state.login)
  const [buttonClick, setButtonClick] = useState(false);
  const [formValues, setFormValues] = useState();
  const navigate = useNavigate();

  const onFileUpload = (file) => {
    if (file.length > 0) {
      if (file[0].name.includes(" ")) {
        toast.error("Please remove spaces from filename", {toastId: toastIds?.FileUploaderr})
      } else {
        setFileData(file)
        // getPresigned(file);
      }
    }
  }

  const onUpdate = (data) => {
    if (fileData.length) {
      let apiBody = {
        batch_id: data.id || "",
      }
      setLoading(true)
      apiCall
        .post(apiConstants?.updateUser, {
          body: apiBody,
          tablename: "debitTransactionReversalResponse",
          whereQuery: {
            id: data.id || "",
          },
        })
        .then(async (res) => {
          if (res?.data?.status === 200) {
            if (res.data.response[0] === 1) {
              getPresigned(fileData, data)
            } else {
              toast.error(res?.data?.message || errMessage?.somethingWentWrong, {
                toastId: toastIds?.FileUploaderr,
              })
            }
          } else {
            toast.error(res?.data?.message || errMessage?.somethingWentWrong, {
              toastId: toastIds?.FileUploaderr,
            })
          }
        })
        .catch((er) => {
          console.log("err", er)
          setLoading(false)
          let resp = er?.message || ""
          toast.error(resp, {toastId: toastIds?.FileUploaderr})
        })
        .finally(() => {})
    } else {
      toast.error(errMessage?.uploadFile, {toastId: toastIds?.FileUploaderr})
    }
  }

  const closeForm = () => {
    window.location.reload()
  }

  const onSubmit = () => {
    if (fileData.length) {
      let userData = loginState?.decodedData
      //   let tempFile = fileData[0].name.split(".")
      let tempData = {...formValues}
      let splitData = tempData["bank_code"]?.value.split("--")
      let apiBody = {
        user_name: userData?.user_name || "",
        file_name: fileData[0]?.name || "",
        upload_status: "Processing",
        total_count: 0,
        succuss_count: 0,
        rejected_count: 0,
        pending_count: 0,
        bank_code: "",
        mandate_type: "rptOtu",
        company_code: "",
        presentation_type: "",
        upload_date_time: moment().format(dateFormats.dateFormatInMDYWithTimeAndAm) || "",
      }

      setLoading(true)
      apiCall
        .post(apiConstants?.createUser, {
          body: apiBody,
          tablename: "debitTransactionReversalResponse",
        })
        .then(async (res) => {
          if (res?.data?.status === 200) {
            if (res?.data?.response?.data?.id) {
              onUpdate(res.data.response.data)
            } else {
              toast.error(res?.data?.message || errMessage?.somethingWentWrong, {
                toastId: toastIds?.FileUploaderr,
              })
            }
          } else {
            toast.error(res?.data?.message || errMessage?.somethingWentWrong, {
              toastId: toastIds?.FileUploaderr,
            })
            setButtonClick(false)
          }
        })
        .catch((er) => {
          console.log("err", er)
          setLoading(false)
          let resp = er?.message || ""
          toast.error(resp, {toastId: toastIds?.FileUploaderr})
        })
        .finally(() => {})
      //   } else {
      //     toast.error(errMessage?.uploadFileCorrectCombination, {
      //       toastId: toastIds?.FileUploaderr,
      //     })
      //   }
    } else {
      toast.error(errMessage?.uploadFile, {toastId: toastIds?.FileUploaderr})
    }
  }

  const uploadLoder = (data) => {
    setUploadPercent(data)
  }

  const getPresigned = (value, data) => {
    const currentDate = moment().format('YYYY_MM_DD')
    const [year , month , day] = currentDate.split('_');
    var batch_id = Math.floor(1000 + Math.random() * 9000)
    setLoading(true)
    apiCall
      .get(
        `${apiConstants.apicommonUtilityPresignedS3Upload}?key=${year}/${month}/${day}/${S3FILEPATH?.DT?.DT}/${S3FILEPATH?.COMMON?.UPLOADS}/${S3FILEPATH?.DT?.DEMERGE}/${S3FILEPATH?.DT?.RAW}/${data.id}/${value[0].name}`,
      )
      .then(async (res) => {
        if (res.status === 200) {
          let result = res?.data
          if (result) {
            value[0].batch_id = batch_id
            value[0].file_type = `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`
            uploadPresigned(value[0], result, uploadLoder)
              .then((res) => {
                toast.success(successMsg?.fileUploadedSuccessfully, {
                  toastId: toastIds?.FileUploadSuccess,
                });
                navigate(ROUTE_PATH?.DEBIT_TRANSACTION_DEMERGE_STATUS)
              })
              .catch((e) => {
                toast.error(e?.message || errMessage?.fileUploadFailed, {
                  toastId: toastIds?.FileUploaderr,
                })
              })
          }
        }
      })
      .catch((er) => {
        toast.error(er?.message || errMessage?.fileUploadFailed, {
          toastId: toastIds?.FileUploaderr,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    setFormValues([
      {
        type: "fileupload",
        width: 12,
        label: "Upload File",
        displaycomponent: <div className="uploadComponentLabel">Upload File</div>,
      },
    ])
  }, [])

  return (
    <div>
      {loading && (
        <div className="className='row justify-content-center d-flex loaderDiv">
          <SystemXLoader />
        </div>
      )}
      <div className="mx-3 pt-4">
        <div className="row justify-content-center">
          <div className="right-div-header">
            <div className="mt-1">
              <br />
              <label className="text-nowrap pl-0 common-header">RPT OTU Upload</label>
              <br />
              <br />
            </div>
          </div>
          <div>
            <div className="mycontainerstyle reportui">
              <div className="pt-4 ms-3">
                <div className="row">
                  <div className="col-xl-9 col-md-6 col-lg-8 col-sm-12">
                    <div className="mr-3">
                      <DynamicForm
                        hideBtn
                        uploadPercent={uploadPercent}
                        onFileUpload={onFileUpload}
                        accept={{
                          "application/vnd.ms-excel": [".xls"],
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
                            ".xlsx",
                          ],
                        }}
                        formValues={formValues}
                      />
                    </div>
                    {/* <UploadComponent  name='upload_files'/> */}
                  </div>
                  <div className="col-xl-3 col-md-6 col-lg-4 col-sm-12"></div>
                  <div className="d-flex gap-3 pt-3 flex-wrap">
                    {/* <button className="bulkMandate-cancelBtn" onClick={() => closeForm()}>Cancel</button> */}
                    <button className="sftpUpload-submitBtn" onClick={() => onSubmit()}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default RptOtu
