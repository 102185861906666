import React, { useEffect, useState } from "react";
import CustomTable from "../../../component/customtable/CustomTable";
import Button from "../../../component/common/Button/button";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../../constants/Routes";
import apiCall from "../../../utils/apiFunction/apiCall";
import {
  apiConstants,
  FOMRS,
  tableNamePaymentConstants,
} from "../../../constants/Common/apiConstants";
import {
  commonVariables,
  tableID,
} from "../../../constants/Common/commonConstants";
import './authenticationMode.scss';
import DeleteConfirmation from "../../../component/deleteModal/deleteModal";
const AuthenticationMode = () => {
  const [enachAuthModeList, setEnachAuthModeList] = useState([])
  const [loading, setLoading] = useState(false)

  const [deleteItemId, setDeleteItemId] = useState({})
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)
  const [latestId, setLatestId] = useState(0)
  console.log(latestId)
  const navigate = useNavigate()

  useEffect(() => {
    fetchEnachAuthModeList()
  }, [])


  const handleDelete = () => {
    apiCall.post(apiConstants?.deleteRecord, {
      tablename: tableNamePaymentConstants?.AUTH_MODE_STATUS,
      whereQuery: {
        id: deleteItemId
      }
    }).then((res) => {
        // console.log(res)
        if(res.data.status === 200) {
          const filteredAuthList = enachAuthModeList.filter(authMode => {
            return authMode?.id !== deleteItemId;
          })
          setEnachAuthModeList(filteredAuthList);
          const listOfAuthIds = filteredAuthList.map(auth => auth?.id)
          setLatestId(listOfAuthIds.length === 0 ? 0 : Math.max(...listOfAuthIds))
        }
      })
      .catch((error) => {
        console.log(error)
      });

    setShowDeleteConfirmationModal(false)
  }

  const fetchEnachAuthModeList = async () => {
    setLoading(true);
    const params = {
      tablename: tableNamePaymentConstants.AUTH_MODE_STATUS,
      attributes: ["id", "auth_mode", "is_active"],
      where: {},
    };
    try {
      const response = await apiCall.post(apiConstants?.findData, params);
      const enachAuthModeList = response?.data?.response || [];
      console.log(response, enachAuthModeList) // auth list

      const listOfAuthIds = enachAuthModeList.map(auth => auth?.id)
      setLatestId(listOfAuthIds.length === 0 ? 0 : Math.max(...listOfAuthIds))

      const newAuthList = enachAuthModeList.map((authMode) => {
        if(authMode.is_active === 1) authMode.active_status = 'Yes'
        else authMode.active_status = 'No'
        authMode.action = (
            <div>
              <img
                className="icon"
                src="/assests/images/pencil.svg"
                onClick={() => {
                  navigate(ROUTE_PATH.CREATE_ENACH_AUTH_MODE, {
                    state: {
                      type: FOMRS.EDIT,
                      data: {id: authMode.id, is_active: authMode.is_active, auth_mode: authMode.auth_mode },
                    },
                  });
                }}
                alt=""
              />
              <img
                className="delete_icon"
                src="/assests/images/trash-bin.svg"
                style={{ padding: "10px", cursor: "pointer" }}
                onClick={() => {
                  setDeleteItemId(authMode?.id)
                  setShowDeleteConfirmationModal(true)
                }}
                alt=""
              />
            </div>
          )
        return authMode;
      })

      setEnachAuthModeList(newAuthList);
    } catch (error) {
      console.error("ERROR ::::::::::::::", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div id="EnachVendor">
      <div className="d-flex flex-wrap align-items-center justify-content-between mt-4 mb-4 mx-3">
        <div className="right-div-header">
          <div>
            <label className="labelPresentation">Enach Authentication Mode</label>
          </div>
        </div>
        <div className="d-flex gap-3 left-div-header">
          <div className="d-flex flex-wrap gap-3">
            <DeleteConfirmation
              showModal={showDeleteConfirmationModal}
              hideModal={() => setShowDeleteConfirmationModal(false)}
              confirmModal={handleDelete}
              id={deleteItemId}
              type={FOMRS.DELETE}
              message="Are you sure you want to delete this item?"
            />
            <Button
              value={"Create Enach Authentication Mode"}
              role="primary"
              onClick={() => {
                navigate(ROUTE_PATH?.CREATE_ENACH_AUTH_MODE, {
                  state: {
                    type: FOMRS.ADD,
                    latestId: latestId
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="setTableMargin">
        {/* {loading && (
          <div className="loaderDiv">
            <SystemXLoader />
          </div>
        )} */}
        <CustomTable
          cols={[
            {
              id: tableID?.ID || "",
              title: commonVariables?.NO || "",
            },
            {
              id: tableID?.AUTH_MODE || "",
              title: commonVariables?.AUTH_MODE || "",
            },
            {
              id: tableID?.IS_AUTH_MODE_ACTIVE || "",
              title: commonVariables?.IS_ACTIVE || "",
            },
            {
                id: tableID?.ACTION,
                title: commonVariables?.ACTION,
            }
          ]}
          data={enachAuthModeList}
        //   count={datacount}
        //   setPagination={(page) => changePage(page)}
        //   activePage={pageNumber}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default AuthenticationMode;
