import React, {useEffect, useState} from "react"
import "./DtPresentationCreate.scss"
import {
  commonVariables,
  componentRole,
  dateFormats,
  S3FILEPATH,
} from "../../../constants/Common/commonConstants"
import {useDispatch, useSelector} from "react-redux"
import {showBar} from "../../../redux/reducer/sideBarStatus"
import FormLabel from "../../../component/common/formLabel/formLabel"
import uploadPresigned from "../../../utils/presignedUpload"
import apiCall from "../../../utils/apiFunction/apiCall"
import Button from "../../../component/common/Button/button"
import {toast} from "react-toastify"
import {Button as ButtonReact, Col, Row} from "react-bootstrap"
import Dropzone from "react-dropzone"
import {ReactComponent as FileIcon} from "../../../Images/file.svg"
import SystemXLoader from "../../../component/common/Loader/SystemXLoader"
import {
  apiConstants,
  errMessage,
  toastIds,
  tableNamePaymentConstants,
} from "../../../constants/Common/apiConstants"
import axios from "axios"
import moment from "moment"

const DebitTransactionPresentaionCreate = (props) => {
  const dispatch = useDispatch()
  const loginState = useSelector((state) => state.login)
  const [fileData, setFileData] = useState([])
  const [loading, setLoading] = useState(false)
  const [apiloading, setApiLoading] = useState(false)
  const [fileValidation, setFileValidation] = useState({
    "text/plain": [".csv", ".txt"],
  })
  const [uploadPercent, setUploadPercent] = useState(0)
  const [systemOptions, setSystemOptions] = useState([])
  const [mandateOptions, setMandateOptions] = useState([])
  const [companyOptions, setCompanyOptions] = useState([])
  const [sponsorOptions, setSponsorOptions] = useState([])
  const [formValues, setFormValues] = useState({
    source_system: {
      value: "",
      error: "",
      required: true,
    },
    mandate_type: {
      value: "",
      error: "",
      required: true,
    },
    company_code: {
      value: "",
      error: "",
      required: true,
    },
    bank_code: {
      value: "",
      error: "",
      required: true,
    },
  })
  const [cancelTokenSource, setCancelTokenSource] = useState(null)
  const [buttonClick, setButtonClick] = useState(false)

  const getSystemList = () => {
    setLoading(true)
    apiCall
      .post(apiConstants?.findData, {
        tablename: "system",
      })
      .then((res) => {
        let tempData = [...res?.data?.response]
        tempData = tempData.map((item) => {
          return {
            value: item?.system_code || "",
            label: item?.system_name || "",
          }
        })
        setSystemOptions([...tempData])
      })
      .catch((err) => {
        console.log("err", err)
        setSystemOptions([])
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const getMandateList = () => {
    setLoading(true)
    apiCall
      .post(apiConstants?.findData, {
        tablename: "mandateType",
      })
      .then((res) => {
        let tempData = [...res?.data?.response]
        tempData = tempData.map((item) => {
          return {
            value: item?.mandate_type || "",
            label: item?.mandate_name || "",
          }
        })
        setMandateOptions([...tempData])
      })
      .catch((err) => {
        console.log("err", err)
        setMandateOptions([])
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const getCompanyList = () => {
    setLoading(true)
    apiCall
      .post(apiConstants?.findData, {
        tablename: "company",
        whereQuery: {
          isActive: "1",
        },
      })
      .then((res) => {
        let tempData = [...res?.data?.response]
        tempData = tempData.map((item) => {
          return {
            value: item?.company_code || "",
            label: item?.company_name || "",
          }
        })
        setCompanyOptions([...tempData])
      })
      .catch((err) => {
        console.log("err", err)
        setCompanyOptions([])
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const getSponsorBank = (value) => {
    if (value) {
      setLoading(true)
      apiCall
        .post(apiConstants?.findData, {
          tablename: "sponsorBank",
          whereQuery: {
            mandate_type: value,
          },
        })
        .then((res) => {
          let tempData = []
          if (res.data.status === 404) {
            toast.error("No data is present for selected mandate type", {
              toastId: "noData",
            })
          } else {
            tempData = [...res?.data?.response]
            tempData = tempData.map((item) => {
              return {
                value: `${item.bank_code}--${item.bank_name}`,
                label: item?.bank_name || "",
              }
            })
          }
          setSponsorOptions([...tempData])
        })
        .catch((err) => {
          console.log("err", err)
          setSponsorOptions([])
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setSponsorOptions([])
    }
  }
  const onChange = (e) => {
    let tempData = {...formValues}
    tempData[e.target.name].value = e.target.value
    e.target.value
      ? (tempData[e.target.name].error = false)
      : (tempData[e.target.name].error = true)
    setFormValues({...tempData})
  }
  const onFileUpload = (file) => {
    // if (file.length > 0) {
    //   getPresigned(file);
    // }
    if (file.length > 0) {
      if (file[0].name.includes(" ")) {
        toast.error("Please remove spaces from filename", {toastId: toastIds?.FileUploaderr})
      } else {
        setFileData(file)
        // getPresigned(file);
      }
    }
  }
  const uploadLoder = (data) => {
    setUploadPercent(data)
  }

  // const getPresigned = (value) => {
  //   setLoading(true);
  //   apiCall
  //     .get(
  //       `${apiConstants.apicommonUtilityPresignedS3Upload}?key=commonUpload/dtPicklistFile/${value[0].name}`
  //     )
  //     .then(async (res) => {
  //       if (res.status === 200) {
  //         let result = res?.data;
  //         if (result) {
  //           setLoading(true);
  //           const cancelToken = axios.CancelToken;
  //           const source = cancelToken.source();
  //           setCancelTokenSource(source);
  //           uploadPresigned(value[0], result, uploadLoder, source)
  //             .then((res) => {
  //               if (res.status == 200) {
  //                 setFileData(value);
  //               }
  //             })
  //             .catch((e) => {
  //               console.log("err", e);
  //               toast.error("File Upload Failed", { toastId: "uploaderror" });
  //             })
  //             .finally(() => {
  //               setLoading(false);
  //             });
  //         }
  //       }
  //     })
  //     .catch((er) => {
  //       console.log("err", er);
  //       toast.error("File Upload Failed", { toastId: "uploaderror" });
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };
  const onSubmit = () => {
    if (fileData.length) {
      let userData = loginState?.decodedData
      let tempFile = fileData[0].name.split(".")

      let tempData = {...formValues}
      let splitData = tempData["bank_code"]?.value.split("--")
      // let apiBody = {
      //   source_system: tempData["source_system"]?.value || "",
      //   mandate_type: tempData["mandate_type"]?.value || "",
      //   company_code: tempData["company_code"]?.value || "",
      //   bank_code: splitData[0] || "",
      //   extension: tempFile[tempFile.length - 1] || "",
      //   fileName: fileData[0]?.name || "",
      //   key: fileData[0]?.name
      //     ? `commonUpload/dtPicklistFile/${fileData[0]?.name}`
      //     : "",
      //   user_name: userData?.user_name || "",
      //   user_code: userData?.user_code || "",
      // };
      const params = {
        tablename: tableNamePaymentConstants?.debitTranRegFileUploadStatus,
        dataFetch: [
          {
            column: "file_name",
            operator: "eq",
            value: fileData[0]?.name,
          },
        ],
        month:0
      }
      apiCall
        .post(apiConstants?.fetchAllData, params)
        .then(async (res) => {
          if (res.status === 200) {
            let result = res?.data?.response
            // if (result?.length > 0) {
            // }
            let apiBody = {
              source_system: tempData["source_system"]?.value || "",
              mandate_type: tempData["mandate_type"]?.value || "",
              company_code: tempData["company_code"]?.value || "",
              bank_code: splitData[0] || "",
              upload_status: commonVariables.PROCESSING,
              upload_date_time: moment(new Date()).format(dateFormats.dateFormatInMDYWithTimeAndAm),
              file_name: fileData[0]?.name || "",
              user_name: userData?.user_name || "",
              user_code: userData?.user_code || "",
            }
            if (result.length === 0) {
              setLoading(true)
              apiCall
                .post(apiConstants?.createUser, {
                  body: apiBody,
                  tablename: "debit_tran_reg_file_upload_status",
                })
                .then(async (res) => {
                  if (res?.data?.status === 200) {
                    if (res?.data?.response?.data?.id) {
                      onUpdate(res.data.response.data)
                    } else {
                      toast.error(res?.data?.message || errMessage?.somethingWentWrong, {
                        toastId: toastIds?.FileUploaderr,
                      })
                    }
                  } else {
                    toast.error(res?.data?.message || errMessage?.somethingWentWrong, {
                      toastId: toastIds?.FileUploaderr,
                    })
                    setButtonClick(false)
                  }
                })
                .catch((er) => {
                  console.log("err", er)
                  setLoading(false)
                  let resp = er?.message || ""
                  toast.error(resp, {toastId: toastIds?.FileUploaderr})
                })
                .finally(() => {})
            } else {
              toast.error(errMessage?.duplicateFileName, {
                toastId: "fileUploadfail",
              })
              dispatch(showBar(false))
              handleCancel()
            }
          }
        })
        .catch((er) => {
          console.log("err", er)
        })
        .finally(() => {})
      
    } else {
      toast.error("Please Upload a file", {toastId: "uploaderror"})
      setButtonClick(false)
    }
  }

  const getPresigned = (value, batch_id) => {
    // setLoading(false);;
    let tempData = {...formValues}
    let tempFile = fileData[0].name.split(".")
    const s3key =
      (tempData["mandate_type"]?.value === "NACH" || tempData["mandate_type"]?.value === "AD"  || tempData["mandate_type"]?.value === "UPI Mandate") &&
      tempFile[tempFile.length - 1] === "csv"
        ? `${loginState?.year}/${loginState?.month}/${loginState?.day}/${S3FILEPATH?.DT?.DT}/${S3FILEPATH?.COMMON?.UPLOADS}/${S3FILEPATH?.DT?.PRESENTATION}/${S3FILEPATH?.DT?.RAW}/${batch_id}/${value[0].name}`
        : `${loginState?.year}/${loginState?.month}/${loginState?.day}/${S3FILEPATH?.DT?.DT}/${S3FILEPATH?.COMMON?.UPLOADS}/${S3FILEPATH?.DT?.PRESENTATION}/${batch_id}/${value[0].name}`
    // console.log("s3Key",s3key ,"mandate_type",tempData["mandate_type"]?.value,"extension",tempFile[tempFile.length - 1],'condition',tempData["mandate_type"]?.value === ('NACH' || 'AD') && tempFile[tempFile.length - 1] === 'csv');
    apiCall
      .get(`${apiConstants.apicommonUtilityPresignedS3Upload}?key=${s3key}`)
      .then(async (res) => {
        if (res.status === 200) {
          let result = res?.data
          if (result) {
            // setLoading(true)
            const cancelToken = axios.CancelToken
            const source = cancelToken.source()
            setCancelTokenSource(source)
            uploadPresigned(value[0], result, uploadLoder, source)
              .then((res) => {
                if (res.status == 200) {
                  setFileData(value)
                  onSubmitApi(batch_id, s3key)
                }
              })
              .catch((e) => {
                toast.error(errMessage?.fileUploadFailed, {toastId: toastIds?.FileUploaderr})
              })
              .finally(() => {
                setLoading(false)
              })
          }
        }
      })
      .catch((er) => {
        toast.error(errMessage?.fileUploadFailed, {toastId: toastIds?.FileUploaderr})
      })
      .finally(() => {
        setLoading(false)
      })
    // }
  }

  const onUpdate = (data) => {
    if (fileData.length) {
      let apiBody = {
        batch_id: data.id || "",
      }
      setLoading(true)
      apiCall
        .post(apiConstants?.updateUser, {
          body: apiBody,
          tablename: "debit_tran_reg_file_upload_status",
          whereQuery: {
            id: data.id || "",
          },
        })
        .then(async (res) => {
          if (res?.data?.status === 200) {
            if (res.data.response[0] === 1) {
              getPresigned(fileData, data.id)
            } else {
              toast.error(res?.data?.message || errMessage?.somethingWentWrong, {
                toastId: toastIds?.FileUploaderr,
              })
            }
          } else {
            toast.error(res?.data?.message || errMessage?.somethingWentWrong, {
              toastId: toastIds?.FileUploaderr,
            })
          }
        })
        .catch((er) => {
          console.log("err", er)
          setLoading(false)
          let resp = er?.message || ""
          toast.error(resp, {toastId: toastIds?.FileUploaderr})
        })
        .finally(() => {})
    } else {
      toast.error(errMessage?.uploadFile, {toastId: toastIds?.FileUploaderr})
    }
  }

  const submitForm = () => {
    if (
      formValues.source_system.value === "FINNONE" &&
      (formValues.mandate_type.value === "NACH" || formValues.mandate_type.value === "AD" || formValues.mandate_type.value === "UPI Mandate")
    ) {
      let tempData = {...formValues}
      console.log("------------------------> UPI-------- ",formValues.mandate_type.value,"======>",tempData)
      tempData.bank_code.required = false
      setFormValues({...tempData})
    } else {
      let tempData = {...formValues}
      tempData.bank_code.required = true
      setFormValues({...tempData})
    }
    if (!validate()) {
      console.log("inside if validate")
      setButtonClick(true)
      onSubmit()
    } else {
      toast.error("Fill all required fields", {toastId: "uploaderror"})
    }
  }

  const onSubmitApi = (batch_Id, s3key) => {
    // if (data.length) {
    if (fileData.length) {
      setLoading(true)
      let userData = loginState?.decodedData
      let tempFile = fileData[0].name.split(".")

      let tempData = {...formValues}
      let splitData = tempData["bank_code"]?.value.split("--")
      let apiBody = {
        source_system: tempData["source_system"]?.value || "",
        mandate_type: tempData["mandate_type"]?.value || "",
        company_code: tempData["company_code"]?.value || "",
        bank_code: splitData[0] || "",
        extension: tempFile[tempFile.length - 1] || "",
        fileName: fileData[0]?.name || "",
        key: s3key || "",
        user_name: userData?.user_name || "",
        user_code: userData?.user_code || "",
        batchId: batch_Id || "",
      }
      console.log("-------------->", apiBody)
      // if (
      //   (tempData["mandate_type"]?.value === "NACH" || tempData["mandate_type"]?.value === "AD") &&
      //   tempFile[tempFile.length - 1] === "csv"
      // ) {
      //   console.log("Inside if ")
      //   dispatch(showBar(false))
      //   handleCancel()
      //   setLoading(false)
      //   toast.success("File Uploaded Successfully", {
      //     toastId: "fileUploadSuccess",
      //   })
      // } else {
        apiCall
          .post("DebitTransaction/DtUpload/DtFileUpload", apiBody)
          .then(async (res) => {
            if (res?.data?.status === 200) {
              toast.success("File Uploaded Successfully", {
                toastId: "fileUploadSuccess",
              })
              dispatch(showBar(false))
              handleCancel()
            } else {
              toast.error(res?.data?.message || errMessage?.somethingWentWrong, {
                toastId: "fileUploadfail",
              })
              setButtonClick(false)
            }
          })
          .catch((er) => {
            console.log("err", er)
            let resp = er?.message || errMessage?.somethingWentWrong
            toast.error(resp, {toastId: "uploaderror"})
          })
          .finally(() => {
            setLoading(false)
          })
      // }
    } else {
      toast.error(errMessage?.uploadFile, {toastId: "uploaderror"})
      setButtonClick(false)
    }
    // }
  }

  const validate = () => {
    let error = false
    let tempForm = {...formValues}
    Object.entries(tempForm).forEach((value) => {
      if (value[1].required) {
        if (value[1].value) {
          tempForm[value[0]].error = false
        } else {
          tempForm[value[0]].error = true
          error = true
          setButtonClick(false)
        }
      }
      return value
    })
    setFormValues({...tempForm})
    return error
  }
  const selectFileVal = () => {
    if (
      formValues.source_system.value === "FINNONE" &&
      (formValues.mandate_type.value === "NACH" || formValues.mandate_type.value === "AD" || formValues.mandate_type.value === "UPI Mandate")
    ) {
      setFileValidation({
        "text/csv": [".csv"],
      })
    } else if (
      formValues.source_system.value === "BANCS" &&
      formValues.mandate_type.value === "NACH" &&
      formValues.bank_code.value === "HDFC"
    ) {
      setFileValidation({
        "text/plain": [".txt"],
      })
    } else if (
      formValues.source_system.value === "BANCS" &&
      formValues.mandate_type.value === "AD"
    ) {
      setFileValidation({
        "text/plain": [".txt"],
      })
    } else if (
      formValues.source_system.value === "BANCS" &&
      formValues.mandate_type.value === "LEG"
    ) {
      setFileValidation({
        "text/plain": [".txt"],
      })
    } else {
      setFileValidation({
        "text/plain": [".csv", ".txt"],
      })
    }
  }

  const handleCancel = () => {
    setButtonClick(false)
    props?.onCancel()
    setUploadPercent(0)
    setSystemOptions([])
    setMandateOptions([])
    setCompanyOptions([])
    setSponsorOptions([])
    setFileData([])
    setLoading(false)
    setFormValues({
      source_system: {
        value: "",
        error: "",
        required: true,
      },
      mandate_type: {
        value: "",
        error: "",
        required: true,
      },
      company_code: {
        value: "",
        error: "",
        required: true,
      },
      bank_code: {
        value: "",
        error: "",
        required: true,
      },
    })
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Upload canceled by user.")
    }
  }

  useEffect(() => {
    getSystemList()
    getMandateList()
    getCompanyList()
    selectFileVal()
  }, [formValues])

  // useEffect(() => {
  //   selectFileVal();
  // }, [formValues]);
  return (
    <>
      {loading || apiloading ? (
        <div className="loaderDiv">
          <SystemXLoader />
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-end mr-3">
            <Button
              role={componentRole?.TRANSPARENT_CANCEL || ""}
              onClick={() => dispatch(showBar(false), handleCancel())}
            />
          </div>
          <div className="d-flex justify-content-start demergeLabel f-5 ms-4">
            <FormLabel value={commonVariables?.PRESENTATION_FILE_UPLOAD || ""} />
          </div>
          <div className="m-4">
            <div className="dynamicFormWrapper">
              <Row className="g-3 gx-5 mb-5">
                <Col lg={12} md={12} sm={12}>
                  <div className="inputDiv mt-1">
                    <p>
                      System <span className="textLabelRed">*</span>
                    </p>
                    <select
                      name="source_system"
                      onChange={(e) => onChange(e)}
                      value={formValues.source_system.value}
                      className="form-select selectInput">
                      <option key="Please Select a Value" hidden value>
                        Please Select a Value
                      </option>
                      {systemOptions &&
                        systemOptions.map((value) => {
                          return <option value={value.value}>{value.label}</option>
                        })}
                    </select>
                    {formValues.source_system.error && formValues.source_system.required && (
                      <p className="textLabelRed m-0 mt-2">System is required</p>
                    )}
                  </div>
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <div className="inputDiv mt-1">
                    <p>
                      Company Code <span className="textLabelRed">*</span>
                    </p>
                    <select
                      name="company_code"
                      onChange={(e) => onChange(e)}
                      value={formValues.company_code.value}
                      className="form-select selectInput">
                      <option key="Please Select a Value" hidden value>
                        Please Select a Value
                      </option>
                      {companyOptions &&
                        companyOptions.map((value) => {
                          return <option value={value.value}>{value.label}</option>
                        })}
                    </select>
                    {formValues.company_code.error && formValues.company_code.required && (
                      <p className="textLabelRed m-0 mt-2">Company Code is required</p>
                    )}
                  </div>
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <div className="inputDiv mt-1">
                    <p>
                      Mandate Type <span className="textLabelRed">*</span>
                    </p>
                    <select
                      name="mandate_type"
                      onChange={(e) => {
                        getSponsorBank(e.target.value)
                        onChange(e)
                      }}
                      value={formValues.mandate_type.value}
                      className="form-select selectInput">
                      <option key="Please Select a Value" hidden value>
                        Please Select a Value
                      </option>
                      {mandateOptions &&
                        mandateOptions.map((value) => {
                          return <option value={value.value}>{value.label}</option>
                        })} 
                    </select>
                    {formValues.mandate_type.error && formValues.mandate_type.required && (
                      <p className="textLabelRed m-0 mt-2">Mandate Type is required</p>
                    )}
                  </div>
                </Col>
                {!(
                  formValues.source_system.value === "FINNONE" &&
                  (formValues.mandate_type.value === "NACH" ||
                    formValues.mandate_type.value === "AD" || 
                    formValues.mandate_type.value === "UPI Mandate")
                ) && (
                  <Col lg={12} md={12} sm={12}>
                    <div className="inputDiv mt-1">
                      <p>
                        Sponsor Bank <span className="textLabelRed">*</span>
                      </p>
                      <select
                        name="bank_code"
                        onChange={(e) => onChange(e)}
                        onClick={() => {
                          if (!sponsorOptions?.length) {
                            if (!formValues.mandate_type.value) {
                              toast.error("First select a mandate type ", {
                                toastId: "mandTypeselect",
                              })
                            } else {
                              toast.error("No data is present for selected mandate type", {
                                toastId: "noData",
                              })
                            }
                          }
                        }}
                        value={formValues.bank_code.value}
                        className="form-select selectInput">
                        <option key="Please Select a Value" hidden value>
                          Please Select a Value
                        </option>
                        {sponsorOptions &&
                          sponsorOptions.map((value) => {
                            return <option value={value.value}>{value.label}</option>
                          })}
                      </select>
                      {formValues.bank_code.error && formValues.bank_code.required && (
                        <p className="textLabelRed m-0 mt-2">Sponsor Bank is required</p>
                      )}
                    </div>
                  </Col>
                )}
                <Col lg={12} md={12} sm={12}>
                  <div className="inputDiv mt-1">
                    <p>Upload File</p>
                    <Dropzone
                      onDrop={onFileUpload}
                      multiple={false}
                      accept={{
                        ...fileValidation,
                      }}
                      disabled={uploadPercent > 0 && uploadPercent < 100}>
                      {({getRootProps, getInputProps}) => (
                        <div
                          {...getRootProps({
                            className:
                              "dropWrapper d-flex align-items-center justify-content-center",
                          })}>
                          <input {...getInputProps()} />
                          <div className="d-flex justify-content-center flex-wrap">
                            <div className="d-flex gap-2 uploadContent">
                              {fileData[0]?.name && <FileIcon />}
                              {uploadPercent > 0 && uploadPercent < 100
                                ? `${uploadPercent}% Uploaded`
                                : fileData[0]?.name || (
                                    <div className="uploadComponentLabel">Upload File</div>
                                  )}
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </Col>
                <div className="d-flex gap-4">
                  <ButtonReact
                    className="customButtonGreenInverted"
                    onClick={() => dispatch(showBar(false), handleCancel())}>
                    Cancel
                  </ButtonReact>
                  <ButtonReact
                    className="customButtonGreen"
                    disabled={
                      (uploadPercent > 0 && uploadPercent < 100) || buttonClick || apiloading
                    }
                    onClick={() => submitForm()}>
                    Submit
                  </ButtonReact>
                </div>
              </Row>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default DebitTransactionPresentaionCreate
