import React, { useEffect, useState } from "react";
import "./Registermandate.scss";
import { EnachStatusCode, StatusCodeFlags } from "../../../constants/Common/commonConstants";
import { useParams,Link } from "react-router-dom";
import logo from "../../../Images/TCL-newlogo.jpg";
import logoImage from "../../../Images/eNachNPCIlogo.png";
import failLogo from "../../../Images/icon.svg";
import successLogo from "../../../Images/tick.svg";
import apiCall from "../../../utils/apiFunction/apiCall";
import { apiConstants, enachTechProcessConstants,  tableNamePaymentConstants,registerMandateManualAttribute,toastIds ,errMessage } from "../../../constants/Common/apiConstants";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import { toast } from "react-toastify";

const ResponseStatus = () => {
  const [response, setResponse] = useState({});
  const [downloadFlag, setDownloadFlag] = useState(false);
  const [auth, setAuth] = useState(false);




  const { key } = useParams();
  const fetchResponseStatusNupay = async() => {
    setDownloadFlag(true)
    const params = {
      tablename: tableNamePaymentConstants?.ManualMandate,
      whereQuery: { nu_unique_id: key },
      attributes:registerMandateManualAttribute,
    };
    await apiCall
      .post(apiConstants?.findData, params)
      .then((result) => {
        if (result?.status === 200) {
          setDownloadFlag(false)
          if (result?.data?.status != 200) return toast.error(errMessage?.InternalServerError, {
            toastId: toastIds?.InternalServerError,
          });
          setAuth(true)
          setResponse(result?.data?.response[0]);
        }
      })
      .catch((error) => {
        setDownloadFlag(false)
        toast.error(error?.message || errMessage?.InternalServerError, {
          toastId: toastIds?.InternalServerError,
        });
      });
  }
  const fetchResponseStatus1 = async () => {
    setDownloadFlag(true)
    const params = {
      tablename: tableNamePaymentConstants?.ManualMandate,
      whereQuery: { bitly_sms_status: key },
      attributes:registerMandateManualAttribute,
    };
    await apiCall
      .post(apiConstants?.findData, params)
      .then((result) => {
        if (result?.status === 200) {
          setDownloadFlag(false)
          if (result?.status === 200) {
            setDownloadFlag(false)
            if (result?.data?.status == 404) { fetchResponseStatusNupay() }
            else {
              if (result?.data?.status != 200) return toast.error(errMessage?.InternalServerError, {
                toastId: toastIds?.InternalServerError,
              });
            setAuth(true)
            setResponse(result?.data?.response[0]);
          }
        }
        }
      })
      .catch((error) => {
        setDownloadFlag(false)
        toast.error(error?.message || errMessage?.InternalServerError, {
          toastId: toastIds?.InternalServerError,
        });
      });
  };


  const updateFeedbackStatus = () => {
    const feedbackStatus = { 'feedback_status': 1 }
    const whereQuery = { bitly_sms_status: key }
    apiCall
      .post(apiConstants?.updateUser, {
        tablename: tableNamePaymentConstants?.ManualMandate,
        whereQuery: whereQuery,
        body: feedbackStatus,
      })
      .then((response) => {
        return response;
      });
    window.location.href = enachTechProcessConstants?.enachFeedbackUrl
  }

  useEffect(() => {
    fetchResponseStatus1()
  }, []);



  return (
    <>
    <div>
    {downloadFlag && (
          <div className="className='row justify-content-center d-flex loaderDiv">
            <SystemXLoader />
          </div>
        )}
           {
        auth === true && (
          <div className="bg">
          <div>
          <nav className="main_navbar d-flex justify-content-center justify-content-lg-end">
            <h5 className="mr-2 mt-1">
              {response.company_code ===
              EnachStatusCode.FINANCIAL_COMPANY_CODE ? (
                <div>
                  {" "}
                  <i className="fa fa-envelope"></i>{" "}
                  <label className="mr-2 text-right">
                    customercare@tatacapital.com
                  </label>
                  <i className="fa fa-phone "></i> <label>1860 267 6060</label>{" "}
                </div>
              ) : (
                <div>
                  {" "}
                  <i className="fa fa-envelope"></i>{" "}
                  <label className="mr-2 text-right">
                    customercare.housing@tatacapital.com
                  </label>
                  <i className="fa fa-phone "></i> <label>1860 267 6060</label>
                </div>
              )}
            </h5>
          </nav>
          <nav className="mynavbar navbar">
            <div className="container d-flex justify-content-center justify-content-lg-between">
              <img className="logo" src={logo} alt="logo" />
              <img className="secondimg" src={logoImage}></img>
            </div>
          </nav>
          </div>
            <div className="container row justify-content-center d-flex mx-auto ">
              <div className=" col-md-4 responsePage">
                <label className="enachLabel d-flex justify-content-center pt-4">
                  {response.mandate_status ===
                  EnachStatusCode.SUCCESS_STATUS_CODE
                    ? "E-Mandate Registration SuccessFull"
                    : "E-Mandate Registration Failed"}
                </label>
                <div className="d-flex justify-content-center">
                  {response.mandate_status ===
                  EnachStatusCode.SUCCESS_STATUS_CODE ? (
                    <img
                      className="failLogo img-fluid mt-5"
                      src={successLogo}
                      alt="successLogo"
                    />
                  ) : (
                    <img
                      className="failLogo img-fluid mt-5"
                      src={failLogo}
                      alt="failLogo"
                    />
                  )}
                </div>
                <div className="reasonLabel d-flex justify-content-center pt-4">
                  {response.mandate_status ===
                  EnachStatusCode.SUCCESS_STATUS_CODE ? (
                    
                    <p className="text-center umrn-note" >
                      Your E-Mandate registration is successfully completed.
                      {/* Your reference ID for E-Mandate Registration is : {" "}
                       <b>{response.source_system_unique_number}</b><br/>
                       Your UMRN No. is : {" "}
	                    <b>{response.umrn_no}</b> */}
                      Your reference ID for E-Mandate Registration is : {" "}<br/>
                      <b>{response?.source_system_unique_number}</b><br/>
                      Your UMRN No. is : {" "}<br/>
                      <div ><b>{response?.umrn_no}</b></div>
                    </p>
                   
                  ) : (
                    <p className="text-center">
                      Your E-Mandate registration is rejected due to Bank Account Validation Failure <b>{response?.response_rejection_reason !=='NA' ? response?.response_rejection_reason :  response?.error_descriptiom || response?.enach_rejected_reason}</b>{" "}
                    </p>
                  )}
                </div>
                { response.mandate_status === EnachStatusCode.SUCCESS_STATUS_CODE &&
                  <div>
                      <p className="text-center">
                      <b>Please share your feedback <Link onClick={() =>  updateFeedbackStatus()}>Here</Link></b>
                    </p>
                </div>
                }
                <div className="note d-flex justify-content-center pt-4">
                  {response.company_code ===
                  EnachStatusCode.FINANCIAL_COMPANY_CODE ? (
                    <p className="text-center">
                      In case of any issues/ query related to E-mandate
                      registration you may write to{" "}
                      <Link to="mailto:customercare@tatacapital.com">
                        customercare@tatacapital.com
                      </Link>{" "}
                      or call 1860 267 6060
                    </p>
                  ) : (
                    <p className="text-center">
                      n case of any issues/ query related to E-mandate
                      registration you may write to{" "}
                      <Link to="mailto:customercare.housing@tatacapital.com">
                        customercare.housing@tatacapital.com
                      </Link>{" "}
                      or call 1860 267 6060
                    </p>
                  )}
                </div>
              </div>
            </div>
  
          <nav className="footer navbar d-flex justify-content-center">
            <label>Copyright © 2020 Tata Capital</label>
          </nav>
        </div>
        )      
    }
    </div> 
    </>
  );
};

export default ResponseStatus;