import React, { useState, useEffect } from "react";
import './createBankNotification.scss';
import Button from "../../../../component/common/Button/button";
import FormLabel from '../../../../component/common/formLabel/formLabel'
import { Formik, Form, Field, ErrorMessage } from "formik";
import { array, object, string } from "yup";
import apiCall from '../../../../utils/apiFunction/apiCall'

import {
  apiConstants,
  FOMRS,
  STATUS,
  tableNamePaymentConstants,
  successMsg, toastIds
} from "../../../../constants/Common/apiConstants";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../../../constants/Routes";
import { toast } from "react-toastify";
// import CustomModal from "../modal/modal";
import ResponseModal from "../../../../component/responseModal/responseModal";

let validSchema = object({
  issue_text: string().required('Notification is required')
});

const CreateBankingNotification = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const formType = state?.type || FOMRS.ADD;

  const headingText =
    formType === FOMRS.ADD ? "Create Banking Team Notification" : "Update Banking Team Notification";

  const [formData, setFormData] = useState({
    issue_text: "",
    display: ""
  });
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  
  const FormSubmit = (data) => {
    // console.log('----------data', data)
    
    // console.log('----------->query', query)

    if (formType === FOMRS.ADD) {

      const createQuery = {
        body: {
          id: Number(state?.latestId) + 1,
          issue_text: data.issue_text,
          display: false
        },
        tablename: tableNamePaymentConstants?.BANKING_TEAM_NOTIFICATION,
      }

      console.log(createQuery)

      apiCall.post(apiConstants?.createUser, createQuery).then((res) => {
        if (res?.status === STATUS.SUCCESS) {
          let statusRes = res?.data?.status
          if (statusRes == 404) {
            let responseForModal = res?.data?.message
            // console.log(responseForModal)
            setModalData(responseForModal)
            setShowModal(true)
          } else {
            navigate(ROUTE_PATH.BANKING_TEAM_NOTIFICATION);
            toast.success(successMsg?.NotificationCreatedSuccessfully, toastIds?.FileUploadSuccess)
          }
        }
      });
    }
  };

  return (
    <>
        {modalData && 
          <ResponseModal
            heading="Banking notification"
            data={modalData}
            show={showModal}
            onHide={() => setShowModal(false)}
            color='danger'
          />
          }
        <Formik
          initialValues={{
            issue_text: formData?.issue_text,
            display: formData?.display
          }}
          validationSchema={validSchema}
          enableReinitialize={true}
          onSubmit={(values) => {
            FormSubmit(values);
          }}
        >
        {({ values, setFieldValue }) => (
          <Form>
            <div id="createEnachVendor">
              <div className="row flexRow">
                <div
                  className="col blueFonts large_f"
                  onClick={() => navigate(ROUTE_PATH?.BANKING_TEAM_NOTIFICATION)}
                >
                  {`Banking Team Notification >> `}
                  <span className="blackFonts">{headingText}</span>
                </div>
              </div>
              <div className="whiteBG">
                <div className="blueBG">
                  <span className="titleFont">{headingText}</span>
                </div>
                <div className="field">

                  <div className="row paddingTop">
                    <div className="col-lg-12 medium_f_bold directionColumn">
                      <div>
                        <FormLabel
                          value="Notification"
                          className="required"
                        />
                      </div>
                      <Field
                        type="text"
                        className="InputField"
                        name="issue_text"
                        placeholder="Notification"></Field>
                      <div className="errorMessage">
                        <ErrorMessage name="issue_text" />
                      </div>
                    </div>
                  </div>   
                  <div className="submitButton">
                    <div className="buttonSets">
                      <Button
                        role="secondary"
                        value="Cancel"
                        onClick={() => navigate(ROUTE_PATH?.BANKING_TEAM_NOTIFICATION)}
                      />
                      <Button
                        role="primary"
                        value="Submit"
                        onClick={() => { }}
                        type="submit"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default CreateBankingNotification;
