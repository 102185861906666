import React, { useEffect, useState } from "react";
import CustomTable from "../../../component/customtable/CustomTable";
import Button from "../../../component/common/Button/button";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../../constants/Routes";
import apiCall from "../../../utils/apiFunction/apiCall";
import {
  apiConstants,
  FOMRS,
  tableNamePaymentConstants,
} from "../../../constants/Common/apiConstants";
import {
  commonVariables,
  tableID,
} from "../../../constants/Common/commonConstants";
import './bankingTeam.scss'
import DeleteConfirmation from "../../../component/deleteModal/deleteModal";
import {Form} from "react-bootstrap";
const BankingTeamNotification = () => {
  const [notificationList, setNotificationList] = useState([])
  console.log(notificationList)
  const [loading, setLoading] = useState(false)

  const [deleteItemId, setDeleteItemId] = useState({})
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)
  const [latestId, setLatestId] = useState(0)
  // console.log(latestId)

  const handleToggle = async (notificationId, currentDisplay) => {
    try {
      // Update in backend
      const response = await apiCall.post(apiConstants.updateUser, {
        tablename: tableNamePaymentConstants.BANKING_TEAM_NOTIFICATION,
        whereQuery: { id: notificationId },
        body: { display: !currentDisplay }
      });
      
      
      setNotificationList(prevList => {
        return prevList.map(notification => {
          if (notification.id === notificationId) {
            // Create a new notification object with updated display
            const updatedNotification = {
              ...notification,
              display: !notification.display
            };
            
            // Update the toggleBtn with the new display value
            updatedNotification.toggleBtn = (
              <Form.Check
                type="switch"
                checked={updatedNotification.display}
                onChange={() => handleToggle(updatedNotification.id)}
              />
            );
            
            return updatedNotification;
          }
          return notification;
        });
      });
    } catch (error) {
      console.error("Error updating display status:", error);
    }
  };

  const navigate = useNavigate()

  useEffect(() => {
    fetchBankingTeamNotification()
  }, [])


  const handleDelete = () => {
    apiCall.post(apiConstants?.deleteRecord, {
      tablename: tableNamePaymentConstants?.BANKING_TEAM_NOTIFICATION,
      whereQuery: {
        id: deleteItemId
      }
    }).then((res) => {
        // console.log(res)
        if(res.data.status === 200) {
          const filteredNotifyList = notificationList.filter(notify => {
            return notify?.id !== deleteItemId;
          })
          setNotificationList(filteredNotifyList);
          const listOfNotifyIds = filteredNotifyList.map(notify => notify?.id)
          setLatestId(listOfNotifyIds.length === 0 ? 0 : Math.max(...listOfNotifyIds))
        }
      })
      .catch((error) => {
        console.log(error)
      });

    setShowDeleteConfirmationModal(false)
  }

  const fetchBankingTeamNotification = async () => {
    setLoading(true);
    const params = {
      tablename: tableNamePaymentConstants.BANKING_TEAM_NOTIFICATION,
      attributes: ["id", "issue_text", "display"],
      where: {},
    };
    try {
      const response = await apiCall.post(apiConstants?.findData, params);
      const notificationResponseList = response?.data?.response || [];
      // console.log(notificationResponseList)

      const listOfNotifyIds = notificationResponseList.map(notify => notify?.id)
      setLatestId(listOfNotifyIds.length === 0 ? 0 : Math.max(...listOfNotifyIds))

      const newNotifyList = notificationResponseList.map((notification) => {

        notification.toggleBtn = (
          <Form.Check
              type="switch"
              checked={notification.display}
              onChange={() => handleToggle(notification.id, notification.display)}
          />
        );

        notification.action = (
            <div>
              <img
                className="delete_icon"
                src="/assests/images/trash-bin.svg"
                style={{ padding: "10px", cursor: "pointer" }}
                onClick={() => {
                  setDeleteItemId(notification?.id)
                  setShowDeleteConfirmationModal(true)
                }}
                alt=""
              />
            </div>
          )
        return notification;
      })

      setNotificationList(newNotifyList);
    } catch (error) {
      console.error("ERROR ::::::::::::::", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div id="EnachVendor">
      <div className="d-flex flex-wrap align-items-center justify-content-between mt-4 mb-4 mx-3">
        <div className="right-div-header">
          <div>
            <label className="labelPresentation">Banking Team Notification</label>
          </div>
        </div>
        <div className="d-flex gap-3 left-div-header">
          <div className="d-flex flex-wrap gap-3">
            <DeleteConfirmation
              showModal={showDeleteConfirmationModal}
              hideModal={() => setShowDeleteConfirmationModal(false)}
              confirmModal={handleDelete}
              id={deleteItemId}
              type={FOMRS.DELETE}
              message="Are you sure you want to delete this item?"
            />
            <Button
              value={"Create Banking Team Notification"}
              role="primary"
              onClick={() => {
                navigate(ROUTE_PATH?.CREATE_BANKING_NOTIFICATION, {
                  state: {
                    type: FOMRS.ADD,
                    latestId: latestId
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="setTableMargin">
        {/* {loading && (
          <div className="loaderDiv">
            <SystemXLoader />
          </div>
        )} */}
        <CustomTable
          cols={[
            {
              id: 'id',
              title: 'Sr No.',
            },
            {
              id: 'issue_text',
              title: 'Notification',
            },
            {
              id: 'toggleBtn',
              title: 'Show',
            },
            {
                id: tableID?.ACTION,
                title: commonVariables?.ACTION,
            }
          ]}
          data={notificationList}
        //   count={datacount}
        //   setPagination={(page) => changePage(page)}
        //   activePage={pageNumber}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default BankingTeamNotification;
