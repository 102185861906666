import React from "react"
import {Routes, Route} from "react-router-dom"
import {ROUTE_PATH} from "../constants/Routes"
import DebitTransactionDemergeStatus from "../pages/debitTransaction/DtDemergeStatus/DtDemergeStatus"
import DebitTransactionPresentaion from "../pages/debitTransaction/DtPresentationStatus/DtPresentationStatus"
import DebitTransactionRepresentationStatus from "../pages/debitTransaction/DtRepresentationStatus/DtRepresentationStatus"
import RptOtu from "../pages/debitTransaction/RptOTU/rptotu"
import UPIExecutionUpdate from "../pages/debitTransaction/UPIExecutionUpdate/UPIExecutionUpdate"
import ProtectedRoute from "../component/ProtectedRoute"
import Mandate from "../pages/enach/Mandate"
import Statuspage from "../pages/enach/Status"
import Apistatusbulk from "../pages/payment/apiStatus/apistatusBulk"
import Apistatusbatch from "../pages/payment/apiStatus/apistatus_batch"
import Report from "../pages/enach/NachReport"
import Reversalapibased from "../pages/payment/ReversalStatus/ApiBased"
import Reportdisburmnetreports from "../pages/payment/Rports/DisbursmentReports"
import Reportdisbursmenttable from "../pages/payment/Rports/DisbursmnetTable"
import Filebasedbulk from "../pages/payment/ReversalStatus/FileBasedBulk"
import Reports from "../pages/enach/Reports/reports"
import DebitTransactionMerge from "../pages/debitTransaction/DtMerge/DtMerge"
import Merge from "../pages/debitTransaction/merge/merge"
import IndusHdfcReport from "../pages/payment/IndusHdfc/indushdfc"
import Reversal from "../pages/payment/Reversal/reversal"
import HDFC from "../pages/debitTransaction/reports/mandateRegistration/hdfcReport/hdfc"
import IndusLand from "../pages/debitTransaction/reports/mandateRegistration/induslandReport/indusland"
import RegistrationBanking from "../pages/debitTransaction/reports/registrationAndBanking/registrationbanking"
import FileBatch from "../pages/payment/ReversalStatus/batch"
import RegularPayment from "../pages/payment/RegularPayment/RegularPayment"
import HoldPayment from "../pages/payment/HoldPayment/HoldPayment"
import UnholdPayment from "../pages/payment/UnholdPayment/UnholdPayment"
import ExceptionPayment from "../pages/payment/ExceptionPayment/ExceptionPayment"
import Accounts from "../pages/AccountValidation/accounts"
import SingleMandate from "../pages/enach/singleMandate/singleMandate"
import VerifyAccountValidation from "../pages/AccountValidation/VerifyAccountValidation/VerifyAccountValidation"
import BillUploadStatus from "../pages/debitTransaction/billUploadStatus/billUploadStatus"
import DebitTransactionDemergeFinnoneDownload from "../pages/debitTransaction/DtDemergeFinnoneDownload/DemergeFinnoneDownload"
import DebitTransactionDemergeSystemCount from "../pages/debitTransaction/DtDemergeSysCountData/DemergeSystemCountData"
import DebitTransactionPresentaionCreate from "../pages/debitTransaction/DtPresentationCreate/DtPresentationCreate"
import ValidationReport from "../pages/AccountValidation/EnachReports/enachreports"
import Bulkmandate from "../pages/enach/bulkMandate/Bulkmandate"
import Registermandate from "../pages/enach/RegisterMandate/registermandate"
import CaseDetails from "../pages/CaseDetails/CaseDetails"
import DisbursementTable from "../pages/payment/DisbursementTable/DisbursementTable"
import DisbursementReport from "../pages/payment/DisbursementReport/DisbursementReport"
import PaymentReport from "../pages/payment/PaymentReport/PaymentReport"
import ResponseStatus from "../pages/enach/RegisterMandate/responseStatus"
import AccountReports from "../pages/AccountValidation/Reports/reports"
import AccountList from "../pages/accountValidation2/AccountList/accountlist"
import MandateDetails from "../component/singlemandate/MandateDetails/MandateDetails"
//userMaster
import User from "../pages/userMaster/user/userMasterUser"
import CreateRole from "../pages/userMaster/Role/createRole/userMasterCreateRole"
import UserType from "../pages/userMaster/userType/userMasterUserType"
//systemMaster Routes
import Company from "../pages/systemMaster/Company/systemMasterCompany"
import CreateCompany from "../pages/systemMaster/Company/createCompany/sytemMasterCreateCompany"
import Mandatetype from "../pages/systemMaster/mandateType/systemMasterMandateType"
import Createmandate from "../pages/systemMaster/mandateType/createMandate/systemMasterCreateMandate"
import Frequency from "../pages/systemMaster/Frequency/systemMasterFrequency"
import CreateFrequency from "../pages/systemMaster/Frequency/createFrequency/systemMasterCreateFrequency"
import CollectionSponsorBank from "../pages/systemMaster/sponsorBank/CollectionSponsorBank/collectionSponsorBank"
import CreateCollectionSponsorBank from "../pages/systemMaster/sponsorBank/CollectionSponsorBank/createCollectionSponsorBank/createCollectionSponsorBank"
import EachEnachAdoptionReport from "../pages/enach/enachAdoptionReport/index"
import BancsSponsorBank from "../pages/systemMaster/sponsorBank/BancsSponsorBank/BancsSponsorBank"
import CreateBancsSponsorBank from "../pages/systemMaster/sponsorBank/BancsSponsorBank/createBancsSponsorBank"
import PaymentType from "../pages/systemMaster/sponsorBank/sponsorBankPayment/paymentType/paymentType"
import CreatePaymentType from "../pages/systemMaster/sponsorBank/sponsorBankPayment/paymentType/createPayment/createPaymentType"
import SponsorPaymentBanks from "../pages/systemMaster/sponsorBank/sponsorBankPayment/sponsorPaymentBank/Pages_sponsorPaymentBank"
import CreatenewSponsorPaymentBank from "../pages/systemMaster/sponsorBank/sponsorBankPayment/sponsorPaymentBank/pages_createaponsorPayment"
import BusinessId from "../pages/systemMaster/sponsorBank/sponsorBankPayment/BusinessId/pages_BusinessId"
import CreateBusinessId from "../pages/systemMaster/sponsorBank/sponsorBankPayment/BusinessId/page_createBusiness"
import ReceiptType from "../pages/systemMaster/sponsorBank/sponsorBankPayment/ReceiptType/pages_ReceiptType"
import CreateReceiptType from "../pages/systemMaster/sponsorBank/sponsorBankPayment/ReceiptType/pages_createRecceiptType"
import NupayBankReports from "../pages/systemMaster/sponsorBank/NupayBankReports/pages_NupayBankReports"
import SingleVerify from "../pages/AccountValidation/SingleVerify/SingleVerify"
import DtSourceManualDownload from "../pages/debitTransaction/DtSoruceManualDownload/DtSourceManualDownload"
import ViewMandate from "../pages/enach/viewMandate/viewMandate"
import {route} from "../constants/Common/commonConstants"
import ProvisionReports from "../pages/Reports/ProvisionReports/provisionReports"
import ScanMergeStatus from "../pages/Reports/ScanbaseReports/scanMergeStatus/scanMergeStatus"
import ScanSftpMergeStatus from "../pages/Reports/ScanbaseReports/scanSftpMergeStatus/scanSftpMergeStatus"
import MandateDobUpdate from "../pages/Reports/OpenMandateDobUpdate/openMandateDobUpdate"
import VerifyData from "../pages/Reports/ScanbaseReports/scanSftpMergeStatus/verifyData/verifyData"
import VerifyMergeData from "../pages/Reports/ScanbaseReports/scanMergeStatus/verifyData/verifyData"
import PaymentLogs from "../pages/Reports/paymentLogs/paymentLogs"
import ProvisionReportAnalysis from "../pages/Reports/ProvisionReportAnalysis/provisionReportAnalysis"
import SwapList from "../pages/swap/swapList"
import SwapReversalList from "../pages/swap/swapReversalList"
import SwapReversalUpload from "../pages/swap/swapReversalUpload/swapReversalUpload"
import ChangePassword from "../component/userMaster/ChangePassword/ChangePassword"
import ErrorPage from "../component/errorPage/errorPage"
import EnachMonths from "../pages/systemMaster2/enachMonths/enachMonths"
import ScanSftpMergeLog from "../pages/Reports/ScanbaseReports/ScansftpMergeLog/ScansftpMergeLog"
import SftpUpload from "../pages/debitTransaction/SftpUpload/sftpUpload"
import SftpTable from "../pages/debitTransaction/sftpTable/sftpTable"
import UPITransaction from "../pages/enach/RegisterMandate/upiTransaction"
import PollingPage from "../pages/enach/RegisterMandate/upiPollingPage"
import OpenMandateReports from "../pages/Reports/OpenMandateReports/openmandate"
import MandateRegistrationDemerge from "../pages/debitTransaction/mandateRegistrationDemerge/mandateRegistartionDemerge"
import PhysicalDemergeStatus from "../pages/debitTransaction/mandateRegistrationDemerge/physicalDemergeStatus/physicalDemergeStatus"
import ScanDemergeStatsus from "../pages/debitTransaction/mandateRegistrationDemerge/scanDemergeStatus/scanDemergeStatus"
import ReversalPayTmBased from "../pages/payment/ReversalStatus/PaytmBased.jsx"
import AutoRegularPayment from "../pages/autoPayment/autoRegularPayment/autoRegularPayment.js";
import AutoPaymentBankMaster from "../pages/autoPayment/autoPaymentBankMaster/autoPaymentBankMaster.js"
import AuthenticationMode from "../pages/systemMaster2/authentication_mode/AuthenticationMode.jsx"
import ToggleAuthModeActiveStatus from "../pages/systemMaster2/authentication_mode/toggleAuthModeStatus/ToggleAuthModeActiveStatus.jsx"

import ContractStatusTrackerViewer from "../pages/payment/ContractStatusViewer/ContractStatusTrackerViewer.jsx"
import BankingTeamNotification from "../pages/payment/BankingTeamNotification/BankingTeamNotification.jsx"
import CreateBankingNotification from "../pages/payment/BankingTeamNotification/CreateBankingNotification/CreateBankingNotification.jsx"

const UserMaster = React.lazy(() => import("../component/userMaster/User"))
const UserRole = React.lazy(() => import("../component/userMaster/Role"))
const ApiUserRegistration = React.lazy(() => import("../component/userMaster/ApiUserRegistration"))
const SingleVerifyStatus = React.lazy(() =>
  import("../pages/AccountValidation/SingleVerifyStatus/SingleVerifyStatus"),
)
const Badrequest = React.lazy(() => import("../component/errorPages/BadGatewayError"))
const UnauthorizedError = React.lazy(() => import("../component/errorPages/unauthorizedError"))
const CreateUserType = React.lazy(() => import("../component/userMaster/createUserType"))
const CreateApiUser = React.lazy(() => import("../component/userMaster/createApiUser"))
const AssignRole = React.lazy(() => import("../component/userMaster/assignRole"))
const Login = React.lazy(() => import("../pages/login"))
const Index = React.lazy(() => import("../pages/index/index"))
const Enach = React.lazy(() => import("../pages/enach"))
const CreateUser = React.lazy(() => import("../pages/createUser/index.js"))
const DynamicForm = React.lazy(() => import("../component/common/DynamicForm/dynamicForm"))
const System = React.lazy(() => import("../pages/System/System"))
const SourceSystemMaster = React.lazy(() =>
  import("../pages/SourceSystemMaster/SourceSystemMaster"),
)
const EnachVender = React.lazy(() => import("../pages/enachVendor/enachVandor"))
const AccountType = React.lazy(() => import("../pages/accountType/accountType"))
const Vendor = React.lazy(() => import("../pages/vender/vendor"))
const Rejection = React.lazy(() => import("../pages/rtpRejection/rtpRejection"))
const Scheme = React.lazy(() => import("../pages/Scheme/Scheme"))
const CreateSystem = React.lazy(() => import("../pages/createSystem/createSystem"))
const CreateSourceSystemMaster = React.lazy(() =>
  import("../pages/createSourceSystem/createSourceSystem"),
)
const CreateEnachVendorMaster = React.lazy(() =>
  import("../pages/createEnachVendor/createEnachVendor"),
)
const CreateAccount = React.lazy(() => import("../pages/createAccount/createAccount"))
const CreateVendor = React.lazy(() => import("../pages/createVendor/createVendor"))
const CreateAccountValidationVendor = React.lazy(() =>
  import("../pages/createAccountValidationVendor/createVendor"),
)
const CreateDisbursmentVendor = React.lazy(() =>
  import("../pages/CreateDisbursmentVendor/CreateDisbursmentVendor"),
)
const CreateREJ = React.lazy(() => import("../pages/createREJ/createREJ"))
const CreateScheme = React.lazy(() => import("../pages/createScheme/createScheme"))
const CreateHealthRejection = React.lazy(() =>
  import("../pages/systemMaster2/rejectionMaster/createHealthCheckRejection"),
)
const HealthRejection = React.lazy(() =>
  import("../pages/systemMaster2/rejectionMaster/healthCheckRejection"),
)
const RegistratioRejection = React.lazy(() =>
  import("../pages/systemMaster2/registrationRejection/registrationRejection"),
)
const CreateRegistratioRejection = React.lazy(() =>
  import("../pages/systemMaster2/registrationRejection/createRegistartionRejection"),
)
const TransactionRejection = React.lazy(() =>
  import("../pages/systemMaster2/transactionRejection/transactionRejection"),
)
const Category = React.lazy(() => import("../pages/systemMaster2/category/category"))
const CaseDetailsApproval = React.lazy(() =>
  import("../pages/CaseDetailsApproval/CaseDetailsApproval"),
)
const AccountValidationStatus = React.lazy(() =>
  import("../pages/AccountValidation/AccountValidationStatus/AccountValidationStatus"),
)
const CreateCategory = React.lazy(() => import("../pages/systemMaster2/category/createCategory"))
const Product = React.lazy(() => import("../pages/systemMaster2/product/product"))
const CreateProduct = React.lazy(() => import("../pages/systemMaster2/product/createProduct"))
const SubProduct = React.lazy(() => import("../pages/systemMaster2/product/subproduct"))
const CreateSubProduct = React.lazy(() => import("../pages/systemMaster2/product/createSubProduct"))
const Micr = React.lazy(() => import("../pages/systemMaster2/micr/micr"))
const Account_validation_vendor = React.lazy(() =>
  import("../pages/systemMaster2/account_validation_vendor/account_validation_vendor"),
)
const Disbursement_vendor = React.lazy(() =>
  import("../pages/systemMaster2/disbursment_vendor/disbursment_vendor"),
)
const CreateMicr = React.lazy(() => import("../pages/systemMaster2/micr/createMicr"))
const CreateTransactionRejection = React.lazy(() =>
  import("../pages/systemMaster2/transactionRejection/createTransactionRejection"),
)
const Paths = () => {
  return (
    <>
      <Routes>
        <Route path={ROUTE_PATH.RESPONSE_STATUS} element={<ResponseStatus />} />
        <Route path={ROUTE_PATH.ROOT} element={<Login />} />
        <Route path={ROUTE_PATH.CHANGEPASSWORD} element={<ChangePassword />} />
        <Route path={ROUTE_PATH.UPI_TRANSACTION_PAGE} element={<UPITransaction />} />
        <Route path={ROUTE_PATH.UPI_POLLING_PAGE} element={<PollingPage />} />
        <Route path={ROUTE_PATH.REGISTER_MANDATE} element={<Registermandate />} />
        <Route element={<ProtectedRoute />}>
          <Route path={ROUTE_PATH.HOME} element={<Index />} />
          <Route path={ROUTE_PATH.ENACH} element={<Enach />} />
          {/* ENACH PATHS */}
          <Route path={ROUTE_PATH.REGISTER_MANDATE} element={<Registermandate />} />
          <Route
            path={ROUTE_PATH.EACH_ENACH_ADOPTION_REPORT}
            element={<EachEnachAdoptionReport />}
          />
          <Route element={<ProtectedRoute />}>
            <Route path={ROUTE_PATH.MANDATE} element={<Mandate />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path={ROUTE_PATH.STATUS} element={<Statuspage />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path={ROUTE_PATH.BULK_MANDATE} element={<Bulkmandate />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path={ROUTE_PATH.SWAP_LIST} element={<SwapList />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path={ROUTE_PATH.SWAP_REVERSAL_LIST} element={<SwapReversalList />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path={ROUTE_PATH.SWAP_UPLOAD} element={<SwapReversalUpload />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path={ROUTE_PATH.MANDATE_DETAILS} element={<MandateDetails />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path={ROUTE_PATH.REPORT} element={<Report />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path={ROUTE_PATH.CONTRACT_STATUS_VIEWER} element={<ContractStatusTrackerViewer />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path={ROUTE_PATH.BANKING_TEAM_NOTIFICATION} element={<BankingTeamNotification />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path={ROUTE_PATH.CREATE_BANKING_NOTIFICATION} element={<CreateBankingNotification />} />
          </Route>
          <Route path={ROUTE_PATH.REPORTS} element={<Reports />} />
          <Route path={ROUTE_PATH.PAYMENT_API_STATUS_BULK} element={<Apistatusbulk />} />
          <Route path={ROUTE_PATH.PAYMENT_SPI_STATUS_BATCH} element={<Apistatusbatch />} />
          <Route path={ROUTE_PATH.REVERSAL_API_BASED} element={<Reversalapibased />} />
          <Route
            path={ROUTE_PATH.REPORT_DISBURSMENT_REPORT}
            element={<Reportdisburmnetreports />}
          />
          <Route path={ROUTE_PATH.REVERSAL_PAYTM_BASED} element={<ReversalPayTmBased />} />
          <Route path={ROUTE_PATH.REPORT_DISBURSMENT_TABLE} element={<Reportdisbursmenttable />} />
          <Route
            path={ROUTE_PATH.DEBIT_TRANSACTION_PRESENTATION}
            element={<DebitTransactionPresentaion />}
          />
          <Route
            path={ROUTE_PATH.DEBIT_TRANSACTION_DEMERGE_STATUS}
            element={<DebitTransactionDemergeStatus />}
          />
          <Route path={ROUTE_PATH.CASE_DETAILS_APPROVAL} element={<CaseDetailsApproval />}></Route>
          <Route
            path={ROUTE_PATH.ACCOUNT_VALIDATION_STATUS}
            element={<AccountValidationStatus />}
          />
          <Route path={ROUTE_PATH.SINGLE_VERIFY} element={<SingleVerify />} />
          <Route
            path={ROUTE_PATH.DEBIT_TRANSACTION_REPRESENTATION_STATUS}
            element={<DebitTransactionRepresentationStatus />}
          />
          <Route path={ROUTE_PATH.RPT_OTU} element={<RptOtu />} />
          <Route
            path={route?.DEBIT_TRANSACTION_SOURCE_MANUAL_DOWNLOAD}
            element={<DtSourceManualDownload />}></Route>
          <Route path={ROUTE_PATH.DEBIT_TRANSACTION_MERGE} element={<DebitTransactionMerge />} />
          <Route
            path={ROUTE_PATH.DEBIT_TRANSACTION_BILLUPLOAD_STATUS}
            element={<BillUploadStatus />}
          />
          <Route
            path={ROUTE_PATH.DEBIT_TRANSACTION_REPORTS_MANDATE_REGISTER_HDFC_REPORT}
            element={<HDFC />}
          />
          <Route path={ROUTE_PATH.SINGLE_MANDATE} element={<SingleMandate />} />
          <Route path={ROUTE_PATH.VERIFY_ACCOUNT} element={<VerifyAccountValidation />} />
          <Route path={ROUTE_PATH.VIEW_MANDATE} element={<ViewMandate />} />
          <Route
            path={ROUTE_PATH.DEBIT_TRANSACTION_REPORTS_REGISTRATION_BANKING}
            element={<RegistrationBanking />}
          />
          <Route path={ROUTE_PATH.PAYMENT_INDUSHDFC} element={<IndusHdfcReport />} />
          <Route
            path={ROUTE_PATH.DEBIT_TRANSACTION_PRESENTATION_CREATE}
            element={<DebitTransactionPresentaionCreate />}
          />

          <Route
            path={ROUTE_PATH.DEBIT_TRANSACTION_DEMERGE_SYSTEM_COUNT}
            element={<DebitTransactionDemergeSystemCount />}
          />
          <Route
            path={ROUTE_PATH.DEBIT_TRANSACTION_DEMERGE_SOURCE_SYSTEM_DOWNLOAD}
            element={<DebitTransactionDemergeFinnoneDownload />}
          />
          <Route
            path={ROUTE_PATH.PHYSICAL_DEMERGE_STATUS}
            element={<PhysicalDemergeStatus />}
          />
          <Route
            path={ROUTE_PATH.SCAN_DEMERGE_STATUS}
            element={<ScanDemergeStatsus />}
          />
          <Route path={ROUTE_PATH.ACCOUNT_VALIDATION_REPORTS} element={<ValidationReport />} />
          <Route path={ROUTE_PATH.CREATE_USER} element={<CreateUser />} />

          <Route path={ROUTE_PATH.CaseDetails} element={<CaseDetails />} />
          <Route path={ROUTE_PATH.MoveToException} element={<CaseDetails />} />
          <Route path={ROUTE_PATH.SINGLE_VERIFY_STATUS} element={<SingleVerifyStatus />} />
          <Route path={ROUTE_PATH.Disbursement_Table} element={<DisbursementTable />} />
          <Route path={ROUTE_PATH.Disbursement_Report} element={<DisbursementReport />} />
          <Route path={ROUTE_PATH.Payment_Report} element={<PaymentReport />} />
          {/* <Route path={ROUTE_PATH.ENACH_REPORT} element={<EnachReport />} /> */}
          <Route
            path={ROUTE_PATH.DEBIT_TRANSACTION_REPORTS_MANDATE_REGISTER_INDUSLAND_REPORT}
            element={<IndusLand />}
          />
          <Route
            path={ROUTE_PATH.PAYMENT_REVERSAL_STATUS_FILE_BASED_BATCH}
            element={<FileBatch />}
          />
          <Route path={ROUTE_PATH.ACCOUNT_VALIDATION_ACCOUNTS} element={<Accounts />} />
          <Route path={ROUTE_PATH.CREATE_ROLE} element={<CreateRole />} />
          <Route path={ROUTE_PATH.CREATE_API_USER} element={<CreateApiUser />} />

          <Route path={ROUTE_PATH.ASSIGN_ROLE} element={<AssignRole />} />

          <Route path={ROUTE_PATH.SYSTEM} element={<System />} />
          <Route path={ROUTE_PATH.SOURCE_SYSTEM_MASTER} element={<SourceSystemMaster />} />
          <Route path={ROUTE_PATH.ENACH_VENDER} element={<EnachVender />} />
          <Route path={ROUTE_PATH.ACCOUNT_TYPE} element={<AccountType />} />
          <Route path={ROUTE_PATH.VENDOR} element={<Vendor />} />
          <Route path={ROUTE_PATH.REJECTION} element={<Rejection />} />
          <Route path={ROUTE_PATH.SCHEME} element={<Scheme />} />
          <Route path={ROUTE_PATH.CREATE_SYSTEM} element={<CreateSystem />} />
          <Route
            path={ROUTE_PATH.CREATE_SOURCE_SYSTEM_MASTER}
            element={<CreateSourceSystemMaster />}
          />
          <Route path={ROUTE_PATH.CREATE_ENACH_VENDOR} element={<CreateEnachVendorMaster />} />
          <Route path={ROUTE_PATH.CREATE_ACCOUNT} element={<CreateAccount />} />
          <Route path={ROUTE_PATH.CREATE_VENDOR} element={<CreateVendor />} />
          <Route
            path={ROUTE_PATH.CREATE_ACCOUNT_VALIDATION_VENDOR}
            element={<CreateAccountValidationVendor />}
          />
          <Route
            path={ROUTE_PATH.CREATE_DISBURSMENT_VENDOR}
            element={<CreateDisbursmentVendor />}
          />
          <Route path={ROUTE_PATH.CREATE_REJ} element={<CreateREJ />} />
          <Route path={ROUTE_PATH.CREATE_SCHEME} element={<CreateScheme />} />

          <Route
            path={ROUTE_PATH.ACCOUNT_VALIDATION_VERIFYBULK}
            element={<VerifyAccountValidation />}
          />
          <Route path={ROUTE_PATH.DEBIT_TRANSACTION_MERGE} element={<Merge />} />
          {/*
           *******DEBITTRANSACTION PATHS END**********
           */}

          {/*
           *
           *
           *PAYMENT PATH START*
           *
           *
           */}
          <Route path={ROUTE_PATH.REVERSAL_File_BASED_Bulk} element={<Filebasedbulk />} />
          <Route path={ROUTE_PATH.REGULAR_PAYMENT} element={<RegularPayment />} />
          <Route path={ROUTE_PATH.HOLD_PAYMENT} element={<HoldPayment />} />
          <Route path={ROUTE_PATH.UNHOLD_PAYMENT} element={<UnholdPayment />} />
          <Route path={ROUTE_PATH.EXCEPTION_PAYMENT} element={<ExceptionPayment />} />
          <Route path={ROUTE_PATH.PAYMENT_REVERSAL} element={<Reversal />} />

          {/* PAYMENT ROUTE END */}
          <Route path={ROUTE_PATH.DYNAMIC_FORM} element={<DynamicForm />} />
          <Route path={ROUTE_PATH.USER} element={<User />} />
          <Route path={ROUTE_PATH.USERTYPE} element={<UserType />} />
          <Route path={ROUTE_PATH.API_USER_REGISTRATION} element={<ApiUserRegistration />} />
          <Route path={ROUTE_PATH.BAD_REQUEST_ERROR} element={<Badrequest />} />
          <Route path={ROUTE_PATH.UNAUTHORIZED_ERROR} element={<UnauthorizedError />} />
          <Route path={ROUTE_PATH.CREATE_USER_TYPE} element={<CreateUserType />} />
          <Route path={ROUTE_PATH.CREATE_HEALTH_REJECTION} element={<CreateHealthRejection />} />
          <Route path={ROUTE_PATH.USER} element={<UserMaster />} />
          <Route path={ROUTE_PATH.ROLE} element={<UserRole />} />
          <Route path={ROUTE_PATH.HEALTH_REJECTION} element={<HealthRejection />} />
          <Route path={ROUTE_PATH.REGISTRATION_REJECTION} element={<RegistratioRejection />} />
          <Route
            path={ROUTE_PATH.CREATE_REGISTRATION_REJECTION}
            element={<CreateRegistratioRejection />}
          />
          <Route path={ROUTE_PATH.TRANSACTION_REJECTION} element={<TransactionRejection />} />
          <Route path={ROUTE_PATH.CATEGORY} element={<Category />} />
          <Route path={ROUTE_PATH.CREATE_CATEGORY} element={<CreateCategory />} />
          <Route path={ROUTE_PATH.PRODUCT} element={<Product />} />
          <Route path={ROUTE_PATH.TRANSACTION_REJECTION} element={<TransactionRejection />} />
          <Route
            path={ROUTE_PATH.CREATE_TRANSACTION_REJECTION}
            element={<CreateTransactionRejection />}
          />
          <Route path={ROUTE_PATH.CREATE_PRODUCT} element={<CreateProduct />} />
          <Route path={ROUTE_PATH.SUB_PRODUCT} element={<SubProduct />} />
          <Route path={ROUTE_PATH.CREATE_SUB_PRODUCT} element={<CreateSubProduct />} />
          <Route path={ROUTE_PATH.MICR} element={<Micr />} />
          <Route
            path={ROUTE_PATH.ACCOUNT_VALIDATION_VENDOR}
            element={<Account_validation_vendor />}
          />
          <Route path={ROUTE_PATH.DISBURSMENT_VENDOR} element={<Disbursement_vendor />} />
          <Route path={ROUTE_PATH.AUTHENTICATION_MODE} element={<AuthenticationMode />} />
          <Route path={ROUTE_PATH.CREATE_ENACH_AUTH_MODE} element={<ToggleAuthModeActiveStatus />} />
          <Route path={ROUTE_PATH.CREATE_MICR} element={<CreateMicr />} />
          <Route path={ROUTE_PATH.COMPANY} element={<Company />} />
          <Route path={ROUTE_PATH.CREATE_COMPANY} element={<CreateCompany />} />
          <Route path={ROUTE_PATH.E_MANDATE_TYPE} element={<Mandatetype />} />
          <Route path={ROUTE_PATH.CREATE_MANDATE_TYPE} element={<Createmandate />} />
          <Route path={ROUTE_PATH.FREQUENCY} element={<Frequency />} />
          <Route path={ROUTE_PATH.CREATE_FREQUENCY} element={<CreateFrequency />} />
          <Route path={ROUTE_PATH.COLLECTION_SPONSOR_BANK} element={<CollectionSponsorBank />} />
          <Route path={ROUTE_PATH.CREATE_SPONSOR} element={<CreateCollectionSponsorBank />} />
          <Route path={ROUTE_PATH.BANCS_SPONSOR_BANK} element={<BancsSponsorBank />} />
          <Route path={ROUTE_PATH.CREATE_BANCS_SPONSOR_BANK} element={<CreateBancsSponsorBank />} />
          <Route path={ROUTE_PATH.PAYMENT_TYPE} element={<PaymentType />} />
          <Route path={ROUTE_PATH.CREATE_SPONSOR_PAYMENT} element={<CreatePaymentType />} />
          <Route path={ROUTE_PATH.SPONSOR_PAYMENT_BANK} element={<SponsorPaymentBanks />} />
          <Route
            path={ROUTE_PATH.CREATE_NEW_SPONSOR_PAYMENT_BANK}
            element={<CreatenewSponsorPaymentBank />}
          />
          <Route path={ROUTE_PATH.BUSINESS_ID} element={<BusinessId />} />
          <Route path={ROUTE_PATH.CREATE_BUSINESS_ID} element={<CreateBusinessId />} />
          <Route path={ROUTE_PATH.RECEIPT_TYPE} element={<ReceiptType />} />
          <Route path={ROUTE_PATH.CREATE_RECEIPT_TYPE} element={<CreateReceiptType />} />
          <Route path={ROUTE_PATH.NUPAY_BANK_REPORTS} element={<NupayBankReports />} />
          <Route path={ROUTE_PATH.ACCOUNT_VALIDATION_REPORT} element={<AccountReports />} />

          <Route path={ROUTE_PATH.ENACH_MONTHS} element={<EnachMonths />} />
          <Route path={ROUTE_PATH.ACCOUNT_VALIDATION_LIST} element={<AccountList />} />
          <Route element={<ProtectedRoute />}>
            <Route path={ROUTE_PATH.PROVISION_REPORTS} element={<ProvisionReports />} />
          </Route>
          <Route path={ROUTE_PATH.MANDATE_UPDATE} element={<MandateDobUpdate />} />
          <Route path={ROUTE_PATH.SCAN_MERGE_STATUS} element={<ScanMergeStatus />} />
          <Route path={ROUTE_PATH.SCAN_MERGE_STATUS_VIEW} element={<VerifyMergeData />} />
          <Route path={ROUTE_PATH.SCAN_SFTP_MERGE_STATUS} element={<ScanSftpMergeStatus />} />
          <Route path={ROUTE_PATH.SCAN_SFTP_MERGE_VIEW} element={<VerifyData />} />

          <Route path={ROUTE_PATH.PAYMENT_LOGS} element={<PaymentLogs />} />
          <Route
            path={ROUTE_PATH.PROVISION_REPORT_ANALYSIS}
            element={<ProvisionReportAnalysis />}
          />
          {/* Auto Payment path */}
          <Route path={ROUTE_PATH.REGULAR_AUTO_PAYMENT} element={<AutoRegularPayment />} />
          <Route path={ROUTE_PATH.AUTO_PAYMENT_BANK_MASTEER} element={<AutoPaymentBankMaster/>} />
          {/* adding sftpMerge log table */}
          <Route path={ROUTE_PATH.SCAN_SFTP_MERGE_LOG} element={<ScanSftpMergeLog />} />

          <Route path="*" element={<ErrorPage />} />

          {/* adding sftpUpload and sftpTable */}
          <Route path={ROUTE_PATH.SFTPUPLOAD} element={<SftpUpload />} />
          <Route path={ROUTE_PATH.UPI_EXECUTION_UPDATE} element={<UPIExecutionUpdate/>}/>
          <Route path={ROUTE_PATH.SFTPTABLE} element={<SftpTable />} />
          <Route path={ROUTE_PATH.OPEN_MANDATE_REPORT} element={<OpenMandateReports />} />
          <Route path={ROUTE_PATH.MANDATE_REGISTRATION_DEMERGE} element={<MandateRegistrationDemerge />} />
        </Route>
      </Routes>
    </>
  )
}

export default Paths
